@import '../../style/scss/app';
@import '../../style/scss/mixins.scss';

.sidebar {
  width: 23%;
  background-color: var(--header-footer-background-color);
  height: 100vh;
  position: fixed;
  color: white;
  transition: .2s;
  z-index: 100;
  border-right: 1px solid black;
  left: -25%;
  transition: .2s;

  .header__sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(100, 100, 140, 0.3);
    padding: 10px;
    border-bottom: 1px solid black;

    .logo__block {
      width: 100%;
      display: flex;
      justify-content: space-between;

      img {
        width: 35px;
        cursor: pointer;
        border-radius: 100%;
      }

      .user__block {
        display: flex;
        align-items: center;
        gap: 10px;

        .arrow__btn {
          border-radius: 100%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .2s;
          cursor: pointer;

          .arrow {
            width: 11px;
            height: 11px;
            rotate: 90deg;
          }
        }

        .arrow__btn:hover {
          background: rgb(0, 0, 0, 0.08);
          transition: .2s;
        }

      }
    }

    .menu__navigate {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27%;
        padding: 6px;
        border: 1px solid rgb(120, 120, 120, 0.2);
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
      }

      .active__btn {
        box-shadow: inset 0 0 20px rgb(55,55,55,0.3);
        border: 1px solid rgb(0, 0, 0, 0.2);
      }

      div:hover {
        background: rgb(0, 0, 0, 0.04);
      }

      img {
        width: 15px;
        height: 15px;
        color: red;
      }
    }
  }
}

.sidebar__open {
  left: 0;
  transition: .2s;
}

.closed__sidebar {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 11;
  top: 50px;
  left: 23%;
}