@import '../../style/scss/app.scss';
@import '../../style/scss/mixins.scss';

.course {
  border-radius: 24px;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s;
  cursor: pointer;
  width: 31%;
  background-color: var(--course-background-color);
  z-index: 0;

  @media (max-width: 1360px) {
    padding: 25px;
  }
  @media (max-width: 1210px) {
    width: 47.5%;
  }
  @media (max-width: 720px) {
    padding: 15px;
  }
  @media (max-width: 640px) {
    width: 100%;
    padding: 25px;
  }
  img {
    width: 95px;
    height: 95px;
    @media (max-width: 1360px) {
      width: 90px;
      height: 90px;
    }
    @media (max-width: 720px) {
      width: 80px;
      height: 80px;
    }
    @media (max-width: 640px) {
      width: 90px;
      height: 90px;
    }
  }

  .course__admin__button__wrapper {
    width: calc(100% - 95px);
    height: 42px;
    display: flex;
    justify-content: flex-end;
    z-index: 10;
  }

  &__name {
    width: 100%;
    height: calc(100% - 95px);
    padding-top: 20px;
    color: var(--color-text);
  }
  button {
    position: relative;
  }
  button:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background: transparent;
  }
}



.course:hover {
  background-color: $color-light-blue-hover;

  Button {
    color: $color-blue;
    background-color: $color-white;
    
  }

  Button:hover {
    background-color: #C8CCE1;
  }
}

.light {
  -webkit-box-shadow: 0 3px 10px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 3px 10px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 0 3px 10px 4px rgba(34, 60, 80, 0.2);
}

