@import '../../style/scss/app';
@import '../../style/scss/mixins.scss';

.footer {
  @include display;
  justify-content: center;
  padding-top: 27px;
  padding-bottom: 30px;
  background-color: var(--header-footer-background-color);

  .footer__content__wrapper {
    @include display;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-wrap: wrap;
      gap: 50px;
    }

    .footer__block {
      width: 27%;
      @media (max-width: 800px) {
        width: 100%;
      }

      &__title {
        font-size: 26px;
        font-weight: 600;
        color: $white-text;
      }

      hr {
        margin-top: 8px;
        border-color: $white-text;
      }

      &__text {
        margin-top: 10px;
        color: $white-text;
      }


      &__links__wrapper {
        gap: 9px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .footer__link {
          display: flex;
          gap: 9px;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          margin-top: 3px;
          color: $white-text;
          img {
            width: 3.4%;
            height: 3.4%;
          }
        }
      } 
    }
  }
}
