@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins';

.user__panel__button {
    width: 100%;
    padding: 15px 10px;
    font-size: 16px;
    color: $white-text;
    @include display;
    gap: 10px;
    background-color: transparent;
    cursor: pointer;
    border: 0;
    transition: 0.3s;
    img {
        @media (min-width: 1000px) {
          width: 24px;
          height: 24px;
        }
        @media (min-width: 1000px) and (max-width: 1439px) {
          width: 20px;
          height: 20px;
        }
        @media (max-width: 999px) {
          width: 16px;
          height: 16px;
        }

    }
}
.user__panel__button:hover {
    background-color: rgb(0,0,0, 0.1);
}