@import "style/scss/app";

@mixin display {
  display: flex;
  align-items: center;
}

@mixin link__menu__header($width, $font-size) {
  width: $width;
  a {
    font-size: $font-size;
    transition: 0.3s;
    color: $color-text;
    padding: 8px 30px;
    border-radius: 6px;
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin theme__changing__button {
  @include display;
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
}

@mixin scroll {
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    background: var(--scroll-bar-trak-bg);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scroll-bar-trak-thumb);
    border-radius: 6px;
    border: 3px solid var(--scroll-bar-trak-bg);
    cursor: pointer;
  }
}

@mixin scroll {
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    background: var(--scroll-bar-trak-bg);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scroll-bar-trak-thumb);
    border-radius: 6px;
    border: 3px solid var(--scroll-bar-trak-bg);
    cursor: pointer;
  }
}