@import '../../style/scss/app.scss';

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-blue;
  z-index: 1000;

  img {
    animation-name: loading;
    animation-duration: 1.4s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    width: 8%;
  }
}

@keyframes loading {
    0% {
      display: flex;
      transform: rotate3d(0, 0, 0, 0deg);
    }
    100% {
      display: flex;
      transform: rotate3d(0, 1, 0, 360deg);
    }
}