@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins.scss';

.user__cart__sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  margin-bottom: 10px ;
  p {
    font-size: 14px;
    width: 60px;
    color: $white-text;
  }
  img {
    width: 40px;
  }
}