@import "style/scss/mixins";

.choice {
  &__text {
    font-size: 20px;
    margin-left: 4px;
    cursor: pointer;

    &_reject {
      color: #981515;
      font-size: 25px;

    }

    &_seccess {
      color: #2DBD65;
      font-size: 25px;

    }

    &_wasCompleted {
      color: #888888;
      font-size: 25px;
    }
  }
}

.block {
  @include display;
  gap: 10px;
  margin: 10px 0;

  &__border {
    &_error {
      border: 3px solid #981515;
      border-radius: 50%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_secces {
      border: 3px solid #2DBD65;
      border-radius: 50%;
      padding: 10px 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}