@import 'style/scss/app';
@import 'style/scss/mixins.scss';

.task {
  min-width: 32%;
  min-height: 200px;
  max-width: 32%;
  max-height: 200px;
  padding: 20px 20px;
  background-color: $color-blue;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  scroll-snap-align: center;
  scroll-snap-stop: always / normal;

  &__result {
    margin-top: 50px;
    color: #fff;
  }

  &__text {
    &__notdone, &__done, &__close {
      color: #fff;
    }
  }


  &__title {
    font-weight: 600;
    font-size: 20px;
    color: $white-text;
    align-self: flex-start;
  }

  &__complexity {
    font-weight: 600;
    font-size: 15px;
    align-self: flex-start;
  }


  &__container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .task__exp {

      font-weight: 500;
      font-size: 16px;
      color: $white-text;

      &__quantity {
        color: $color-exp;
      }
    }

    .task__isdone {
      display: flex;

      &__status {
        width: 20px;
        height: 20px;
        border-radius: 180px;
        margin-left: 10px;
      }
    }
  }
}

.easy {
  color: $color-easy-complexity;
}

.medium {
  color: $color-medium-complexity;
}

.hard {
  color: $color-hard-complexity;
}

.done__color {
  color: $color-task-done;
}

.notdone__color {
  color: $color-task-notdone;
}

.close__color {
  color: $color-task-close;
}

.done {
  background-color: $color-task-done;
}

.notdone {
  background-color: $color-task-notdone;
}

.close {
  background-color: $color-task-close;
}