@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins';

.menu__chat {

  .user__list {
    display: flex;
    gap: 20px;
    padding: 10px;
    overflow-x: auto;
  }

  .chat__panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .message__list {
      min-height: 63vh;
      max-height: 63vh;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
  .chat {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}