@import '../../style/scss/app.scss';
@import '../../style/scss/mixins.scss';

.admin__panel {
  @include display;
  justify-content: center;
  .line__wrapper__search {
    display: flex;

  }

  .admin__content__wrapper {
    width: calc(100% - 240px);
    min-height: 100vh;
    margin: 50px 0;
    @media (max-width: 1160px) {
      width: calc(100% - 70px);
    }



    .admin__panel__title__wrapper {
      width: 100%;
      margin-top: 150px;
      @include display;
      justify-content: space-between;
      flex-wrap: wrap;


      .admin__panel__title {
        color: var(--color-text);
        font-size: 20px;
        font-weight: 600;
      }

      hr {
        width: 100%;
        margin-top: 15px;
        border-color: var(--color-text);
        margin-bottom: 15px;
      }


      .admin__panel__title {
        font-size: 20px;
        font-weight: 700;
        color: var(--color-text);
      }

      hr {
        width: 100%;

        margin-top: 15px;
        border-color: var(--color-text);

        margin-bottom: 15px;

        p {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .courses__list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 50px 3%;
      margin-top: 50px;
      @media (max-width: 1360px) {
        gap: 45px 3%;
        margin-top: 45px;
      }
      @media (max-width: 1210px) {
        gap: 45px 4.5%;
        margin-top: 40px;
      }
      @media (max-width: 720px) {
        gap: 35px 3%;
        margin-top: 35px;
      }
      @media (max-width: 640px) {
        gap: 30px 0;
        margin-top: 30px;
      }
    }


    .admin__panel__users__title {
      width: 100%;
      margin-top: 150px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      @media (max-width: 850px) {
        flex-direction: column;
        gap: 0;
      }


      .admin__panel__users__filter {
        width: 100%;
        max-width: 510px;
        display: flex;
        gap: 10px;
        font-size: 20px;
        font-weight: 600;
        @media (max-width: 850px) {
          max-width: none;
        }
        @media (max-width: 600px) {
          gap: 5px;
        }

        &__button {
          padding: 10px 15px;
          cursor: pointer;
          border-radius: 5px 5px 0 0;
          color: var(--color-text);
          background-color: var(--admin-panel-filter-button-background);
          width: 50%;
          @media (max-width: 600px) {
            padding: 10px 10px;
            font-size: 15px;
          }
          @media (max-width: 450px) {
            padding: 10px 5px;
            font-size: 14px;
          }
        }

        .active {
          color: var(--admin-panel-filter-button-active-color);
          background-color: var(--admin-panel-filter-button-active-background);
        }
      }

      hr {
        border-color: var(--color-text);
      }



      .admin__panel__search {
        width: 60%;
        max-width: 400px;
        @include display;
        border-radius: 8px;
        background-color: var(--admin-panel-search-background);
        display: flex;
        @media (max-width: 850px) {
          width: 100%;
          max-width: none;
        }


        &__input {
          width: 100%;
          max-width: 350px;
          border: none;
          border-radius: 8px;
          outline: none;
          padding: 15px;
          font-size: 14px;
          color: var(--color-text);
          background-color: transparent;
        }
        &__input::placeholder {
          font-size: 14px;
        }

        &__button {
          height: 100%;
          padding: 25px;
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: center;
          border: none;
          background-color: transparent;
          cursor: pointer;
          background-image: var(--admin-panel-search-button-icon);
        }
      }

      hr {
        width: 100%;
      }
    }

    .admin__panel__users__list {
      width: 100%;
      padding: 25px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
    }
    .banned__user__list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      width: 100%;
    }
  }

  .admin__panel__achievements__list {
    width: 100%;
    padding: 25px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;

  }

  .hr__search__max {
    @media (max-width: 850px) {
      display: none;
    }
  }
  .hr__search__min {
    @media (min-width: 850px) {
      display: none;
    }
  }
}
