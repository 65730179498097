@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins.scss';

.theme__changing {
  @include display;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.4);
  align-items: center;
  justify-content: center;

  img {
    width: 70%;
    max-width: 70%;
  }

  &__dark__button {
    @include theme__changing__button;
    color: var(--theme-changing-dark-button-text-color);
    background-color: var(--theme-changing-dark-button-background);
    border-radius: 100%;

  }

  &__light__button {
    @include theme__changing__button;
    color: var( --theme-changing-light-button-text-color);
    background-color: var(--theme-changing-light-button-background);
  }
}

@keyframes change__theme {
  0% {
    rotate: 240deg;
    filter: blur(.3px);
    box-shadow: 0 0 15px rgba(10, 10, 10, 10.4);
  }
  50% {
    filter: blur(.6px);
    box-shadow: 3px 3px 25px rgba(0, 0, 0, 5);
  }
  100% {
    rotate: 360deg;
    filter: blur(.3px);
    box-shadow: 0 0 15px rgba(10, 10, 10, 0.4);
  }
}
.theme__changing:not(:active) {
  animation: change__theme 0.5s 1;
}