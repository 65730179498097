@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins';

.header {
  @include display;
  background-color: var(--header-footer-background-color);
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
  padding: 0 50px;
  transition: .15s;

  .content__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 60px;
    .btn__sidebar {
      display: flex;
      flex-direction: column;
      width: 40px;
      height: 40px;
      gap: 4px;
      transition: .2s;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;
      hr {
        border: 1px solid white;
        width: 40%;
      }
    }
    .btn__sidebar:hover {
      background: rgb(0,0,0,0.1);
    }
    .btn__sidebar__open {
      opacity: 0;
      transition: .2s;
    }

    .title {
      color: $white-text;
      font-size: 24px;
    }
  }
}

.header__open {
  width: 85%;
  margin-left: 18%;
  transition: .2s;
}

.content__wrapper__open {
  width: 100%;
  display: flex;
  align-items: center;
}
