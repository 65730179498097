@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins.scss';

.course__lesson {
  width: 100%;
  margin: 40px 0;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-radius: 11px;
    background-color: $color-blue;
    cursor: pointer;

    &__title {
      font-weight: 600;
      font-size: 17px;
      color: $white-text;
    }

    .course__lesson__states__wrapper {
      @include display;
      gap: 20px;

      .lesson__arrow {
        height: 20px;
        width: 20px;
        rotate: -180deg;
        transition: 0.2s;
      }
      .lesson__arrow.open {
        rotate: 0deg;
        transition: 0.2s;
      }

      .course__lesson__done__status {
        width: 45px;
        height: 45px;
        box-shadow: 0 0 10px rgb(200,200,200,0.2);
        border-radius: 100%;

      }
    }
  }

  &__taskstrings {
    overflow: hidden;
    max-height: 0;
    transition: .3s;
  }

  .task__string__block {
    display: flex;

  }
}