@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins.scss';

.notification__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  margin: 10px 0;
  @media (max-width: 1240px) {
    gap: 20px;
    margin: 8px 0;
  }

}
.title__notification__text {
   margin: 15px 10px 10px 10px;
  color: $white-text;
 }