@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';


.user__card {
  min-height: 150px;
  border-radius: 8px;
  color: var(--color-text);
  background-color: var(--admin-panel-user-card-background);
  padding: 15px;
  width: 31%;

  @media (max-width: 1210px) {
    width: 48%;
  }

  @media (max-width: 850px) {
    width: 100%;
  }

  img {
    max-width: 60px;
  }

  .user__info__banned {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .user__title__section__ban {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .ban__btn {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .user__card__ban__button {
    padding: 12px 25px;
    background: #BB0000;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
  }

}

.light {
  -webkit-box-shadow: 0px 3px 10px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 3px 10px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 3px 10px 4px rgba(34, 60, 80, 0.2);
}
