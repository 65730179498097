@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';
.content__wrapper {
  display: flex;
  justify-content: center;
  .home__questions__block {
  width: 100%;
  @include display;
  justify-content: center;
  margin-bottom: 50px;
  @media (max-width: 750px) {
    margin: 20px 0;
  }

  .home__questions__title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 50px;
    color: var(--color-text);
    @media (max-width: 750px) {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }

  hr {
    border-color: var(--line-main-background-color-change);
  }
}
}
