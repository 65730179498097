@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.question {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title__block {
    @include display;
    justify-content: space-between;

    img {
      transition: .3s;
    }

    .question__title {
      width: 100%;
      padding: 50px 0;
      cursor: pointer;
      font-size: 25px;
      font-weight: 600;
      color: var(--color-text);
      @media (max-width: 750px) {
        font-size: 22px;
        padding: 35px 0;
      }
      @media (max-width: 500px) {
        font-size: 18px;
        padding: 20px 0;
      }

    }

  }


  .question__answer__block {
    width: 100%;
    padding-bottom: 50px;
    transition: .3s;
    max-height: 0;
    overflow: hidden;

    .question__text {
      font-size: 18px;
      color: var(--color-text);
      @media (max-width: 750px) {
        font-size: 16px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }

}

.question:after {
  content: '';
  width: 100%;
  height: 1px;
  background: var(--line-main-background-color-change);
}