@import "style/scss/mixins";

.table {
  &__head {
    @include display;
    justify-content: space-around;
    background-color: var(--tabel-head-bg);
    padding: 20px 0 20px 80px;
    border-radius: 20px 20px 0 0;

    &_text {
      color: var(--color-text);
      font-weight: 700;
      width: 100%;
    }
  }
}