@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.rating__list__user__one__place {
  border: 2px solid $color-top-gold;
}

.rating__list__user__two__place {
  border: 2px solid $color-top-silver;
}

.rating__list__user__three__place {
  border: 2px solid $color-top-bronze;
}

.rating__list__user__other {
  -webkit-box-shadow: 0 1px 10px 1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 1px 10px 1px rgba(34, 60, 80, 0.2);
  box-shadow: 0 1px 10px 1px rgba(34, 60, 80, 0.2);
}

.rating__list__user {
  width: 93%;
  height: 100px;
  display: flex;
  border-radius: 5px;
  background-color: var(--header-footer-background-color);
  color: $white-text;
  cursor: pointer;
  box-shadow: 5px 5px 14px rgba(0, 0, 0, 0.5);
  padding: 15px 20px;

  .rating__user__place {
    width: 10%;
    height: 100%;
    font-size: 30px;
    @include display;
    padding-left: 25px;
    color: $white-text;
  }

  .top__places {
    width: 10%;
    height: 100%;
    @include display;
    padding-left: 25px;

    .top__places__place {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
    }

    .top__one__place {
      background-image: url('../../img/place_one_icon.png');
    }

    .top__two__place {
      background-image: url('../../img/place_two_icon.png');
    }

    .top__three__place {
      background-image: url('../../img/place_three_icon.png');
    }
  }

  .rating__user {
    width: 75%;
    height: 100%;
    @include display;

    .rating__user__image {
      width: 65px;
      height: 65px;
      border-radius: 180px;
      @include display;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .rating__user__info {
      display: flex;
      flex-direction: column;
      padding-left: 15px;

      &__name {
        color: #fff;
        font-size: 20px;
      }

      &__rank {
        color: #fff;
      }

      &__status {
        font-size: 14px;
        color: $white-text;
      }

      &__rank {
        color: $white-text;
      }
    }
  }

  .rating__user__info__exp {
    height: 100%;
    width: 15%;
    @include display;
    color: $color-exp;
    font-size: 20px;
  }
}
