@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.compiler {
  @include display;
  justify-content: center;
  height: 100vh;

  &__btn {
    width: 150px;
    padding: 13px 30px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    background-color: #06ab06;
    margin-right: 20px;

    &:hover {
      background-color: #06ab06;
    }
  }

  &__link {
    width: 150px;
    padding: 13px 30px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    color: $white-text;
    background-color: $color-blue;

    &:hover {
      background-color: var(--admin-button-hover-background);
    }
  }


  .compiler__content__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 90px;

    @media (max-width: 834px) {
      margin-top: 60px;
    }
    @media (max-width: 375px) {
      margin-top: 50px;
    }

    .compiler__theory {
      &__name {
        font-size: 20px;
      }

      &__subtitle {
        margin-top: 15px;
      }
    }


    .compiler__task__theory {
      width: 100%;
      display: flex;
      max-height: 500px;
      flex-direction: column;


      @media (max-width: 880px) {
        height: 20vh;
        width: 100%;
      }


      &__title {
        font-size: 22px;
        font-weight: 600;
        color: var(--color-text);
        border-bottom: 2px solid var(--compiler-hr);
        padding-bottom: 15px;
        margin: 20px 0;
      }

      &__text {
        padding: 15px 25px 6px 6px;
        line-height: 22px;
        height: calc(80vh - 80px);
        overflow-y: scroll;
        @include scroll;

        span {
          filter: var(--invert-text);
          font-size: 20px;
        }

        a {
          filter: var(--invert-text);
          font-size: 20px;
          text-decoration: underline;
        }
      }
    }

    .compiler__wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 10px;
      gap: 10px;
      margin-top: 20px;


      @media (max-width: 880px) {
        width: 100%;
        height: 60vh;
        margin: 20px 0 0 0;
      }

      .compiler__user__code {
        width: 100%;
        height: 75%;
        border-radius: 8px;
        background-color: var(--compiler-bg);
        overflow: hidden;
        overflow-y: auto;
        padding: 10px 0;
        @media (max-width: 880px) {
          height: 65%;
        }
      }

      .compiler__user__code::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .compiler__console {
        padding-top: 20px;
        width: 100%;
        height: 25%;
        @include display;
        justify-content: center;
        flex-direction: column;

        @media (max-width: 880px) {
          height: 35%;
        }

        .compiler__console__title__wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 25%;


          .compiler__console__title {
            background-color: var(--compiler-bg);
            color: var(--color-text);
            padding: 12px;
            max-width: 150px;
            font-weight: 600;
            font-size: 16px;
            width: 100%;
            clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 10%);
            border-radius: 8px 0 0 0;
            @include display;
          }

          .compiler__console__buttons__wrapper {
            @include display;
            gap: 10px;
            padding-bottom: 8px;
          }

          @media (max-width: 500px) {
            .compiler__console__title {
              font-size: 14px;
            }
            .compiler__console__buttons__wrapper {

              Button {
                padding: 10px;
                @media (max-width: 400px) {
                  padding: 5px;
                }
              }
            }
          }
        }

        hr {
          width: 100%;
          border: 1px solid #bfbfbf;
        }

        .compiler__console__text {
          height: 75%;
          overflow: hidden;
          overflow-y: auto;
          width: 100%;
          padding: 12px;
          font-weight: 500;
          color: var(--color-text);
          background-color: var(--compiler-bg);

          .compiler__console__result {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
          }

          .compiler__console__result.success {
            color: $color-task-done;
          }

          .compiler__console__result.failed {
            color: $color-error;
          }
        }

        .compiler__console__text::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
  }
}

@media (max-width: 880px) {
  .compiler__content__wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}


