@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.title {
    display: grid;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-row-gap: 18rem;
        position: relative;
    }
    @media (max-width: 700px) {
        grid-row-gap: 13rem;
    }
    @media (max-width: 600px) {
        grid-row-gap: 4rem;
    }
    @media (max-width: 500px) {
        grid-row-gap: 0;
    }
    &__logo {
        position: relative;
        z-index: 1;
        display: grid;
        justify-content: center;
        margin: 50px 0 0 50px;
        align-items: start;
        grid-template-columns: 1fr 15fr;
        grid-auto-rows: 100px;
        grid-column-gap: 2rem;
        @media (max-width: 1350px) {
            grid-column-gap: 1.2rem;
            grid-auto-rows: 50px;
            margin: 40px 0 0 40px;
        }
        @media (max-width: 1200px) {
            margin: 30px 0 0 30px;
        }
        @media (max-width: 700px) {
            margin: 20px 0 0 20px;
            grid-column-gap: 1rem;
            grid-template-columns: 1.5fr 15fr;
        }
        
        h1 {
            color: white;
            font-size: 34px;
            display: grid;
            height: 100%;
            align-items: center;
            @media (max-width: 1350px) {
                font-size: 29px;
            }
            @media (max-width: 1200px) {
                font-size: 27px;
            }
            @media (max-width: 1025px) {
                font-size: 25px;
            }
            @media (max-width: 700px) {
                font-size: 20px;
            }
        }
        img {
            width: 90%;
            height: 90%;
            @media (max-width: 700px) {
                width: 100%;
                height: 100%;
            }
        }
        
    }
    &__inf {
        display: grid;
        position: relative;
        z-index: 1;
        color: white;
        margin: 0 0 50px 50px;
        align-items: end;
        font-size: 29px;
        @media (max-width: 1350px) {
            font-size: 26px;
            margin: 0 0 40px 40px;
        }
        @media (max-width: 1200px) {
            font-size: 23px;
            margin: 0 0 30px 30px;
        }
        @media (max-width: 1025px) {
            font-size: 20px;
        }
        @media (max-width: 500px) {
            font-size: 16px;
        }
    }
    .bgReg {
        position: absolute;
        top: 0;
        left: 0;
        width: 80%;
        height: 100%;
        z-index: 0;
        @media (max-width: 900px) {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
