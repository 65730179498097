@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.content__wrapper {
  display: flex;
  justify-content: center;

  .courses__block {
    width: 100%;
    justify-content: center;
    padding: 70px 0;
    display: inline-block;
  
    .courses__list__title {
        display: flex;
        font-size: 39.5px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--courses-list-title);
    }
  
    hr {
      border-color: var(--line-main-background-color-change);
    }
  
    .courses__list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 50px 3%;
      margin-top: 50px;
      @media (max-width: 1360px) {
        gap: 45px 3%;
        margin-top: 45px;
      }
      @media (max-width: 1210px) {
        gap: 45px 4.5%;
        margin-top: 40px;
      }
      @media (max-width: 720px) {
        gap: 35px 3%;
        margin-top: 35px;
      }
      @media (max-width: 640px) {
        gap: 30px 0;
        margin-top: 30px;
      }
    }
  }
}