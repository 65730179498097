@import "style/scss/mixins";


.table {
  &__user {
    @include display;
    padding: 6px 0 6px 80px;
    border-bottom: 1px solid #dadada;
    background-color: var(--tabel-users-bg);

    &_text {
      color: var(--color-text);
      width: 100%;
      cursor: pointer;
    }

    &_avatar {
      width: 60px;
      height: 60px;
      cursor: pointer;

      &_block {
        width: 100%;
      }
    }
  }

  &__link {
    cursor: pointer;
    color: #3c54da;
    width: 100%;
  }
}