@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins';

.notification {
  display: flex;
  justify-content: start;
  padding: 0 10px;
  gap: 15px;

  @media (max-width: 850px) {
    gap: 10px;
    padding: 0 10px;
  }
  @media (max-width: 750px) {
    gap: 10px;
    padding: 0 8px;
  }

  .logo__notification {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      @media (max-width: 750px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  .text__notification {
    display: flex;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
    h3 {
      color: $white-text;
      font-weight: 600;
      font-size: 16px;
      @media (max-width: 1240px) {
        font-size: 15px;
      }
    }
    .description__notification {
      font-size: 14px;
      color: $white-text;
      @media (max-width: 1240px) {
        font-size: 13px;
      }
    }
    .go__notification__button {
      color: $white-text;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
