@import 'style/scss/app.scss';

.lesson__tasks__block {
  display: flex;
  width: 100%;
  .lesson__tasks__list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 2%;
    margin-left: 15px;
    width: 100%;
    margin-top: 20px;
  }


}