@import '../../../style/scss/app';
@import '../../../style/scss/mixins';

.profile__top__image {
  width: 100%;
  height: 386px;
  background-image: url('img/profile_preview_image.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  @include display;
  justify-content: center;
  @media (max-width: 950px) {
    height: 286px;
  }
  @media (max-width: 500px) {
    height: 220px;
  }

  .profile__top__image__content__wrapper {
    width: calc(100% - 240px);
    height: 100%;
    @media (max-width: 1140px) {
      width: calc(100% - 70px);
    }

    .profile__top__image__text {
      height: 100%;
      @include display;
      color: $white-text;
      font-size: 24px;
      font-weight: 500;
      @media (max-width: 950px) {
        font-size: 16px;
      }
      @media (max-width: 500px) {
        font-size: 14px;
      }

    }

  }

  .user__profile__tool__buttons {
    @include display;
    justify-content: center;
    gap: 50px;
    height: 55px;
    position: absolute;
    left: 0;
    margin-top: 560px;
    margin-left: 120px;
    @media (max-width: 1140px) {
      margin-left: 35px;
    }
    @media (max-width: 950px) {
      margin-top: 345px;
    }
  
    @media (max-width: 500px) {
      margin-top: 275px;
    }
  
    .user__profile__tool__button {
      height: 100%;
      padding: 0 22px;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      font-size: 16px;
      color: $white-text;
      transition: .3s;
      border-radius: 5px 5px 0 0;
      margin-bottom: 230px;
    
      @media (max-width: 950px) {
        margin-bottom: 110px;
      }
    
      @media (max-width: 500px) {
        font-size: 14px;
        padding: 0 15px;
      }
    
    }
    .user__profile__tool__button:hover {
      background-color: $color-gray-hover;
    }
    .active {
      border-bottom: 3px solid $color-gray;
    }
  }
}

.user__profile__wrapper {
  width: 100%;
  @include display;
  justify-content: center;


  .user__profile__content__wrapper {
    width: calc(100% - 240px);
    height: 100%;
    position: relative;

    @media (max-width: 1140px) {
      width: calc(100% - 70px);
    }


    .left__userinfo__wrapper {
      width: 25%;
      height: 100%;
      display: flex;
      position: relative;
      justify-content: center;
      @include display;
      .online {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .not__online__color {
        background-color: red;
      }

      .online__color {
        background-color: #2DBD65;
      }

      .status__circle {
        width: 15px;
        height: 15px;
        border-radius: 100%;
      }

      .user__avatar__wrapper {
        width: 50%;
        height: 215px;
        @include display;
        flex-direction: column;

        @media (max-width: 950px) {
          height: 195px;
        }


        .user__avatar {
          width: 186px;
          height: 186px;
          min-height: 186px;
          min-width: 186px;
          border-radius: 186px;

          @media (max-width: 1440px) {
            width: 160px;
            height: 160px;
            min-height: 160px;
            min-width: 160px;
          }
        }

        .user__rank, .user__exp {
          padding-top: 10px;
          font-size: 16px;
          font-weight: 600;
        }

        .user__exp {
          color: $color-exp;
        }
        .user__rank {
          color: var(--profile-user-rank-color);
        }
      }
    }

    .user__profile {
      min-height: 400px;
      width: 100%;
      margin: 70px 0;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      @media (max-width: 950px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        margin: 45px 0;
        min-height: 700px;
      }

      .userinfo__form {
        @include display;
        gap: 25px;
        flex-direction: column;
        justify-content: center;
        width: 30%;
        max-width: 500px;
        height: 100%;
      
        @media (max-width: 950px) {
          width: 100%;
        }

        button {
          padding: 10px;
          font-size: 16px;
          width: 100%;
          background: $color-blue;
          color: white;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: .3s;
        }
        button:hover {
          scale: 0.98;
          background: $color-blue-hover;
        }
      }

      .user__achievements__wrapper {
        width: 55%;
        max-width: 700px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
      
        @media (max-width: 950px) {
          width: 75%;
        }
        @media (max-width: 650px) {
          width: 90%;
        }
        @media (max-width: 570px) {
          width: 100%;
        }
      
      
      
        .user__achievements__title {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-text);
        }
      
        .user__achievements__list {
          width: 100%;
          max-height: 450px;
          border-radius: 15px;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          overflow: hidden;
          overflow-y: scroll;
          @media (max-width: 1440px) {
            gap: 15px;
          }

          Li {
            width: calc((100% - 20px) / 2);
            @media (max-width: 1440px) {
              width: calc((100% - 15px) / 2);
            }
          }
        }
        .user__achievements__list::-webkit-scrollbar {
          width: 10px;
      
        }
        .user__achievements__list::-webkit-scrollbar-thumb {
          border-radius: 15px;
        }
      }
    }


    .user__courses__block {
      margin: 75px 0;
      display: flex;

      @media (max-width: 950px) {
        flex-direction: column;
        align-items: center;

      }

      .user__started__courses__list {
        width: 100%;
        height: 100%;
        margin-left: 100px;
        @include display;
        flex-wrap: wrap;
        gap: 50px;


        @media (max-width: 1650px) {
          gap: 30px;
          margin-left: 40px;
        }
        @media (max-width: 1280px) {
          gap: 20px;
          margin-left: 0;
        }
        @media (max-width: 950px) {
          gap: 20px;
          padding-top: 55px;
          margin-bottom: 100px;
        }
        @media (max-width: 730px) {
          gap: 10px;
          padding-top: 50px;
          margin-bottom: 90px;
        }
        @media (max-width: 700px) {
          gap: 20px;
          padding-top: 50px;
          justify-content: center;
          margin-bottom: 80px;
        }

      }
    }
  }
}

.not__courses {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25%;
  font-size: 36px;
  color: grey;

  @media (max-width: 950px) {
    margin-left: 0;
    font-size: 34px;
    margin-top: 50px;
  }
  @media (max-width: 400px) {
    margin-left: 0;
    font-size: 30px;
    margin-top: 50px;
  }
}
