@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.sidebar {
    display: grid;
    background-color: #EDEDF3;
    z-index: 1;
    align-items: center;
    justify-content: center;
    grid-template-columns: 70%;
    &__form {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr;
        grid-row-gap: 1.2rem;
        @media (max-width: 1350px) {
            grid-row-gap: 0.8rem;
        }
        @media (max-width: 1025px) {
            grid-row-gap: 0.6rem;
        }
        @media (max-width: 900px) {
            margin-top: 20px;
        }
        @media (max-width: 600px) {
            margin-top: 0;
        }
        .log__btn {
            padding: 10px 0;
            margin-top: 1rem;
            @media (max-width: 1350px) {
                margin-top: 0.6rem;
                padding: 8px 0;
            }
            @media (max-width: 1200px) {
                font-size: 13px;
            }
            @media (max-width: 1025px) {
                font-size: 11px;
            }
        }
        h2 {
            display: grid;
            justify-content: center;
            @media (max-width: 1350px) {
                font-size: 20px;
            }
        }   
    }
    .register {
        display: grid;
        grid-template-columns: 5fr 6fr;

        @media (max-width: 1350px) {
            font-size: 14px;
        }
        @media (max-width: 1200px) {
            font-size: 12px;
        }
        @media (max-width: 1025px) {
            font-size: 10px;
        }
        p {
            font-weight: 600;
        }
        &__text {
            display: grid;
            justify-content: end;
        }
        &__link {
            color: #283A97;
        }
    }
}