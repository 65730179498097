@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.rating__page {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--color-text);
  padding-top: 60px;

  &__content__wrapper {
    width: 85%;
    height: calc(100% - 100px);

    .rating__header {
      gap: 10px;
      padding: 0 4px;
      margin: 10px 0;
      width: 100%;
      @include display;

      .panel__search {
        width: 60%;
        max-width: 400px;
        @include display;
        border-radius: 8px;
        background-color: var(--admin-panel-search-background);
        display: flex;
        height: 50px;
        @media (max-width: 850px) {
          width: 100%;
          max-width: none;
        }


        input {
          width: 100%;
          max-width: 350px;
          border: none;
          border-radius: 8px;
          outline: none;
          font-size: 14px;
          color: var(--color-text);
          background-color: transparent;
        }

        input::placeholder {
          font-size: 14px;
        }

        button {
          height: 100%;
          padding: 25px;
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: center;
          border: none;
          background-color: transparent;
          cursor: pointer;
          background-image: var(--admin-panel-search-button-icon);
        }
      }

      p {
        display: flex;
        align-items: flex-end;
        padding-bottom: 15px;
        font-size: 20px;
      }

      &__place {
        width: 10%;
        margin-left: 20px;
      }

      &__user {
        width: 75%;
        margin-left: -10px;
      }

      &__exp {
        width: 15%;
      }


    }


    .rating__list {
      width: 100%;
      height: 100%;
      padding: 25px 0;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }

    .rating__list::-webkit-scrollbar {
      width: 0;
    }
  }

  .this__user__rating {
    width: 100%;
    height: 100px;
    @include display;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--header-footer-background-color);

    &__content__wrapper {
      width: 85%;
      height: 100%;
      display: flex;

      .this__user__place {
        width: 10%;
        height: 100%;
        font-size: 30px;
        @include display;
        padding-left: 25px;
        color: $white-text;
      }

      .this__user__place__wrapper {
        width: 10%;
        height: 100%;
        @include display;
        padding-left: 25px;

        .this__user__top__place {
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
          width: 40px;
          height: 40px;
        }

        .top__one__place {
          background-image: url('./img/place_one_icon.png');
        }

        .top__two__place {
          background-image: url('./img/place_two_icon.png');
        }

        .top__three__place {
          background-image: url('./img/place_three_icon.png');
        }
      }


      .this__user {
        width: 75%;
        height: 100%;
        @include display;


        &__image__wrapper {
          width: 65px;
          height: 65px;
          border-radius: 180px;
          @include display;
          justify-content: center;

          .this__user__image {
            width: 100%;
            height: 100%;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          padding-left: 15px;

          &__name {
            font-size: 20px;
            color: $white-text;
          }

          &__status {
            color: $white-text;
            font-size: 14px;
          }

        }
      }
    }

    .this__user__exp {
      height: 100%;
      width: 15%;
      @include display;
      color: $color-exp;
      font-size: 20px;
    }

  }
}

.rating__search__input {
  margin-left: 0;
  border-radius: 6px;
  padding: 10px 0;
  font-size: 18px;
}
  





