@import '../../../../../../style/scss/app.scss';

.left__line {
  height: 100%;
  width: 20px;
  min-width: 20px;
  position: relative;
  display: flex;
  justify-content: center;

    &__square {
      position: absolute;
      top: calc(100% - 100px);
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      width: 20px;
      background-color: $left-line-square-background;
      z-index: 2;
    }

    hr {
      position: absolute;
      transform: translate(50%, 50%);
      rotate: 180deg;
      border: 1px solid $left-line-color;
      height: 100%;
      top: 50%;
    }

}
