@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.preview__section {
    @include display;
    align-items: center;
    justify-content: center;
    background-image: url('../../img/home_preview_image.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 60px 0;
    margin-left: -5px;
  
  
    @media (max-width: 1440px) {
      padding: 8vh 0;
    }
    @media (max-width: 750px) {
      padding: 6vh 0;
    }
    @media (max-width: 500px) {
      padding: 4vh 0;
    }
    @media (max-width: 375px) {
      margin-top: 50px;
    }
  
    .preview__text__wrapper {
      height: 50%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16vh;
  
      .preview__title {
        color: $home-title-color;
      }
  
      .preview__title__text,
      .preview__text {
        color: $white-text;
        transition: 0.3s;
      }
  
      .preview__title, .preview__title__text {
        font-size: 40px;
        @media (max-width: 750px) {
          font-size: 24px;
        }
        @media (max-width: 400px) {
          font-size: 22px;
        }
      }
  
      .preview__text {
        font-size: 24px;
        @media (max-width: 750px) {
          font-size: 16px;
        }
        @media (max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }