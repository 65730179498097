@import '../../style/scss/app.scss';

.light {
  -webkit-box-shadow: 0px 3px 10px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 3px 10px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 3px 10px 4px rgba(34, 60, 80, 0.2);
}

.achievement {
  background-color: var(--achievement-background);
  min-height: 130px;
  border-radius: 6px;
  display: flex;
  padding: 15px;
  gap: 25px;
  width: 31%;
  @media (max-width: 1210px) {
    width: 48%;
  }
  @media (max-width: 850px) {
    width: 100%;
  }

  .achievement__photo {
    max-width: 60px;
    max-height: 60px;
    margin-bottom: 20px;
  }

  .achievement__text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    width: 40%;

    .achievement__title {
      color: var(--color-text);
      font-size: 20px;
      font-weight: 600;
    }

    .achievement__description {
      color: var(--color-text);
      font-size: 15px;
    }
  }

  .achievement__admin {
    display: flex;
    justify-content: end;
    width: 42%;

    .edit__btn {
      width: 24px;
      height: 24px;
      background-image: var(--admin-panel-edit-button-img);
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
      cursor: pointer;
      right: 10px;
      top: 10px;
    }

    .delete__btn {
      width: 24px;
      height: 24px;
      background-image: url('../../style/img/delete-icon.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
      cursor: pointer;
      right: 44px;
      top: 10px;
    }
  }
}


