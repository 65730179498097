@import "style/scss/mixins";

.answer {

  width: 900px;
  height: calc(80vh - 100px);
  margin: 150px auto;
  background-color: var(--tabel-users-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 2px 4px 10px #000;
  overflow-y: scroll;
  @include scroll;

  &__bg {
    @include display;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(61, 58, 58, 0.73);
    width: 100%;
    height: 100%;
  }
  &__close {
    text-align: right;
    cursor: pointer;
    border-bottom: 1px solid var(--color-text);
    margin-bottom: 20px;
  }
}